<template>
  <div>
    <v-card flat class="soft_shadow">
      <v-card-text>
        <v-row class="">
          <v-col cols="12" sm="12" md="5" lg="6" class="d-flex align-center">
            <h2 class="font-weight-bold header--text d-flex align-end">
              <img
                v-if="imgUrl"
                :src="imgUrl"
                alt="alt"
                width=""
                style="max-width: 30px; opacity: 0.8"
              />
              <v-icon v-else color="pla_c3 darken-1" large left>
                {{ icon }}
              </v-icon>
              <span class="headerColor--text pb-1 mt-3">{{ title }}</span>
            </h2>
          </v-col>
          <v-col
            v-if="breadCrumbs"
            cols="12"
            sm="12"
            md="7"
            lg="6"
            class="d-flex justify-md-end"
          >
            <v-row no-gutters>
              <v-col cols="12" class="d-flex align-center justify-end">
                <v-chip label color="pla_c3" class="ml-12 float-right">
                  <v-icon v-show="showIcon" small color="pla_c3 darken-4">
                    mdi-store
                  </v-icon>
                  <p
                    class="
                      font-weight-bold
                      pla_c3--text
                      text--darken-4
                      py-0
                      my-0
                    "
                    :class="showIcon ? 'pl-4' : ''"
                  >
                    Establecimiento: {{ establishmentName }}
                  </p>
                </v-chip>
              </v-col>
              <v-col
                cols="12"
                align="right"
                class="d-flex justify-end align-end"
              >
                <BreadCrumbs :items-bread-crumbs="breadCrumbs"></BreadCrumbs>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            v-else
            cols="12"
            sm="12"
            md="7"
            lg="6"
            class="d-flex justify-md-end"
          >
            <v-chip label color="pla_c3" class="ml-12 float-right">
              <v-icon v-show="showIcon" small color="pla_c3 darken-4"
                >mdi-store</v-icon
              >
              <p
                class="font-weight-bold pla_c3--text text--darken-4 py-0 my-0"
                :class="showIcon ? 'pl-4' : ''"
              >
                Establecimiento: {{ establishmentName }}
              </p>
            </v-chip>
          </v-col>
          <v-col v-if="text" cols="12" class="">
            <h3 class="support--text font-weight-medium py-0 my-0 pl-2">
              {{ text }}
            </h3>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import BreadCrumbs from "./partials/BreadCrumbs";
import { mapState } from "vuex";
export default {
  name: "myHeaderView",
  props: {
    icon: String,
    imgUrl: String,
    title: String,
    text: String,
    breadCrumbs: Array,
  },
  data: function () {
    return {
      currentSystem:
        process.env.VUE_APP_APP ||
        (window.location.host.includes("contamagica") ? "disagro" : "mynube"),
    };
  },
  components: {
    BreadCrumbs,
  },
  computed: {
    showIcon() {
      return this.currentSystem !== "disagro";
    },
    ...mapState({
      establishmentName: (state) => state.config.establishmentName,
    }),
  },
};
</script>
<style scoped src="@/assets/css/globals.css"></style>
<style scoped>
p {
  font-size: 0.8rem;
}
</style>
