<template>
  <v-breadcrumbs :items="ItemsBreadCrumbs">
    <template v-slot:divider>
      <v-icon>mdi-chevron-right</v-icon>
    </template>
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
        :active-class="
          !item.disabled ? 'support--text' : 'primary--text text--lighten-2'
        "
        :to="{ name: `${item.url}`, params: {} }"
        :disabled="item.disabled"
        link
      >
        {{ item.text }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  name: "BreadCrumbs",
  props: {
    ItemsBreadCrumbs: { Array },
  },
};
</script>

<style scoped>
.v-breadcrumbs {
  padding: 10px 12px 5px 12px;
}
</style>
