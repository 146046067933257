<template>
  <v-card
    v-if="currentSystem === 'disagro'"
    @click="gotoRoute(url)"
    class="mx-auto"
    min-height="320"
    color="secondary"
    hover
  >
    <v-row class="pt-5">
      <v-col cols="12">
        <h3 class="pl-5 pl-lg-10 white--text">{{ title }}</h3>
      </v-col>
      <v-col cols="12">
        <v-img
          :src="icon ? icon : '/img/icons/custom/disagro/lista.png'"
          class="white--text align-end"
          :max-height="200"
          contain
        >
        </v-img>
      </v-col>
    </v-row>
  </v-card>
  <v-card
    v-else
    @click="gotoRoute(url)"
    class="mx-auto"
    max-width="550"
    min-height="150"
    :color="backgroundColor"
    hover
  >
    <v-img
      :src="imgUrl ? imgUrl : '/img/menus/catalogos/actividades.jpg'"
      class="white--text align-end"
      :gradient="getGradient(backgroundColor)"
      max-height="150"
    >
      <v-card-title v-text="title"></v-card-title>
    </v-img>
  </v-card>
</template>

<script>
export default {
  name: "myMenuCard",
  props: {
    title: String,
    description: String,
    icon: String,
    url: String,
    imgUrl: String,
    backgroundColor: String,
  },
  data: function () {
    return {
      currentSystem:
        process.env.VUE_APP_APP ||
        (window.location.host.includes("contamagica") ? "disagro" : "mynube"),
      availableColors: {
        primary: "to bottom, rgba(0,139,0,.30), rgba(14,80,14,.66)",
        pla_c3: "to bottom, rgba(133,140,0,.30), rgba(80,80,14,.66)",
        support: "to bottom, rgba(121,121,118,.40), rgba(50,50,51,.86)",
      },
    };
  },
  methods: {
    gotoRoute(url) {
      if (url) {
        this.$router.push({ name: url });
      }
    },
    getGradient(backgroundColor) {
      let gradient = this.availableColors[backgroundColor];
      if (!gradient) {
        return "to bottom, rgba(121,121,118,.40), rgba(50,50,51,.86)";
      }
      return gradient;
    },
  },
};
</script>

<style scoped></style>
